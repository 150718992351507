const ProductionConfig = {
  environment: 'production',
  apiUrl: '/api/v1',
  recaptchaKey: '6Lf0hS4pAAAAADA4iZT7LWK0-B5INGeXdHASkNY0',
  walletConnectProjectId: 'fa79da9c7e9095967987c523b4aa7a04',
};

const StagingConfig = {
  environment: 'staging',
  apiUrl: 'https://nftmember.1fit.fun/api/v1',
  recaptchaKey: '6LfNhi4pAAAAANfaRw0PEDiVxcPVxfL_BA5n0HIt',
  walletConnectProjectId: 'c9dde83741f0f9a7d967c7546f9f1a1e',
};

const stagingDomain = 'nftmember.1fit.fun';
const isProduction =
  window.location.hostname.indexOf(stagingDomain) < 0 &&
  window.location.hostname.indexOf('localhost') < 0;

const systemConfig = {
  ...(isProduction ? ProductionConfig : StagingConfig),
  accessTokenKey: 'f4E6TQLb4v2',
  web3AddressKey: 'HDosajshav2',
  devKey: 'IJIdiasdbas',

  files: {
    whitepaper: {
      en: 'https://whitepaper.walkup.world/english/walk-to-your-wealth',
      ko: 'https://whitepaper.walkup.world/english/walk-to-your-wealth',
      ja: 'https://whitepaper.walkup.world/english/walk-to-your-wealth',
    },
  },
};

export default systemConfig;
