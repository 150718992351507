import { Box, IconButton, Stack, Typography, alpha } from '@mui/material';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useLocales } from 'src/locales';
import Iconify from '../iconify';

const ImageUploader = ({ images, onRemove, disabled, previewImgStyle = {}, ...other }) => {
  const { t } = useLocales();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: true,
    maxFiles: 4 - images.length,
    ...other,
  });

  const imagePreviews = images.map((image, index) => (
    <Stack key={index} position="relative">
      <img
        src={image.preview}
        alt={`Preview ${index}`}
        style={{ width: 120, height: 120, objectFit: 'cover', ...previewImgStyle }}
      />
      <IconButton sx={{ position: 'absolute', top: 1, right: 1 }} onClick={() => onRemove(index)}>
        <Iconify icon="ic:round-close" />
      </IconButton>
    </Stack>
  ));

  return (
    <Box className="image-uploader">
      {images.length < 4 && (
        <Stack
          {...getRootProps()}
          sx={{
            cursor: 'pointer',
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
          }}
          alignItems="center"
          justifyContent="center"
          p={4}
          mb={2}
        >
          <input {...getInputProps()} />
          <Iconify icon="lets-icons:img-box-fill" width={32} />
          <Typography>{t('nft_members_lang_271')}</Typography>
          <Typography variant="body2" color="text.secondary">
            {t('nft_members_lang_272')}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        {imagePreviews}
      </Stack>
    </Box>
  );
};

export default ImageUploader;

ImageUploader.propTypes = {
  images: PropTypes.array,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  previewImgStyle: PropTypes.object,
};
