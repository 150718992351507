import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DevGuard from 'src/auth/guard/dev-guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

const WalletPage = lazy(() => import('src/pages/wallet'));

const ComWalletPage = lazy(() => import('src/pages/com-wallet'));

const ComTransactionsPage = lazy(() => import('src/pages/com-transactions'));

const InventoryPage = lazy(() => import('src/pages/inventory'));

const StakingNFTPage = lazy(() => import('src/pages/staking-nft'));

const StorePage = lazy(() => import('src/pages/store'));

const MarketplacePage = lazy(() => import('src/pages/marketplace'));

const ProfilePage = lazy(() => import('src/pages/profile'));

const ProfileAffiliatePage = lazy(() => import('src/pages/profile-affiliate'));

const StakingPage = lazy(() => import('src/pages/staking'));

const SupportPage = lazy(() => import('src/pages/support'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'inventory', element: <InventoryPage /> },
      { path: 'staking-nft', element: <StakingNFTPage /> },
      { path: 'marketplace', element: <StorePage /> },
      {
        path: 'open-marketplace',
        element: (
          <DevGuard>
            <MarketplacePage />
          </DevGuard>
        ),
      },
      { path: 'wallet', element: <WalletPage /> },
      { path: 'wallet/com-wallet', element: <ComWalletPage /> },
      { path: 'wallet/com-transactions', element: <ComTransactionsPage /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: 'profile/affiliate', element: <ProfileAffiliatePage /> },
      { path: 'staking', element: <StakingPage /> },
      { path: 'support', element: <SupportPage /> },
    ],
  },
];
