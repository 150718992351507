// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const PAGE_SIZE = [10, 20, 40, 50, 80, 100];
export const ASSETS = ['MATIC', 'WKW', 'WKE'];

export const RARITY_CONFIG = {
  TRIAL: {
    label: 'TRIAL',
    color: '#607274',
  },
  COMMON: {
    label: 'COMMON',
    color: '#55c636',
  },
  RARE: {
    label: 'RARE',
    color: '#30a5b7',
  },
  EPIC: {
    label: 'EPIC',
    color: '#a137cd',
  },
  LEGENDARY: {
    label: 'LEGENDARY',
    color: '#bc3231',
  },
  LEGEND: {
    label: 'LEGEND',
    color: '#bc3231',
  },
};

export const TOKENS = {
  matic: {
    imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    name: 'MATIC',
  },
  wut: {
    imgUrl: 'https://files.walkup.world/WUT.png',
    name: 'WUT',
  },
  wup: {
    imgUrl: 'https://files.walkup.world/WUP.png',
    name: 'WUP',
  },
  usd: {
    imgUrl: 'https://files.walkup.world/USD.png',
    name: 'USD',
  },
  bonc: {
    imgUrl: 'https://files.walkup.world/BONC.png?v=1',
    name: 'BONC',
  },
  swup: {
    imgUrl: 'https://files.walkup.world/WUP.png',
    name: 'sWUP',
  },
};
