import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, IconButton, InputAdornment } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import systemConfig from 'src/config/system-config';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import fetcher from 'src/utils/fetcher';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

export default function ForgotPasswordView() {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { t } = useLocales();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [step, setStep] = useState(1);

  const [errorMsg, setErrorMsg] = useState('');

  const newPwd = useBoolean();
  const confirmPwd = useBoolean();

  const isSubmitting = useBoolean();

  // ----------------------------------------------------------------------

  const handleReCaptchaVerify = useCallback(
    async (successCallback) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('login');
      if (successCallback)
        successCallback(systemConfig.environment === 'staging' ? 'recaptcha' : token);
    },
    [executeRecaptcha]
  );

  // ----------------------------------------------------------------------

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    newPassword:
      step === 2 &&
      Yup.string()
        .required('New Password is required')
        .min(8, 'Password must be at least 8 characters'),
    confirmPassword:
      step === 2 && Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    otp:
      step === 3 &&
      Yup.string()
        .min(6, 'OTP Code must be at least 6 characters')
        .required('OTP Code is required'),
  });

  const defaultValues = {
    email: '',
    newPassword: '',
    confirmPassword: '',
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = handleSubmit(async (values) => {
    if (step === 1) setStep(2);
    if (step === 2) {
      handleSendRequest(values);
    }
    if (step === 3) {
      handleResetPassword(values);
    }
  });

  const handleSendRequest = async (values) => {
    await handleReCaptchaVerify((token) => {
      isSubmitting.onTrue();

      setErrorMsg('');

      fetcher().post(
        `/user/send-reset-password-code?email=${values.email}&recaptcha=${token}`,
        undefined,
        () => {
          setStep(3);
          isSubmitting.onFalse();
        },
        (error) => {
          setErrorMsg(error.msg || error.code);
          isSubmitting.onFalse();
        }
      );
    });
  };

  const handleResetPassword = async (values) => {
    await handleReCaptchaVerify((token) => {
      const params = {
        email: values.email,
        password: values.newPassword,
        otp: values.otp,
        recaptcha: token,
      };

      setErrorMsg('');

      isSubmitting.onTrue();

      fetcher().put(
        '/user/reset-password',
        params,
        () => {
          enqueueSnackbar('Reset password successful!');
          router.push('/login');
          isSubmitting.onFalse();
        },
        (error) => {
          setErrorMsg(error.msg || error.code);
          isSubmitting.onFalse();
        }
      );
    });
  };

  const enterEmailForm = (
    <Stack spacing={3}>
      <RHFTextField name="email" label={t('nft_members_lang_133')} />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting.value}
      >
        {t('nft_members_lang_125')}
      </LoadingButton>

      <Stack alignItems="flex-end">
        <Link
          component={RouterLink}
          href="/login"
          color="inherit"
          variant="subtitle2"
          sx={{
            alignItems: 'center',
            display: 'inline-flex',
          }}
        >
          <Iconify icon="eva:arrow-ios-back-fill" width={16} />
          {t('nft_members_lang_145')}
        </Link>
      </Stack>
    </Stack>
  );

  const enterPwdForm = (
    <Stack spacing={3}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField
        name="newPassword"
        label="New Password"
        type={newPwd.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={newPwd.onToggle} edge="end">
                <Iconify icon={newPwd.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="Confirm Password"
        type={confirmPwd.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={confirmPwd.onToggle} edge="end">
                <Iconify icon={confirmPwd.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting.value}
      >
        {t('nft_members_lang_170')}
      </LoadingButton>
    </Stack>
  );

  const enterOtpForm = (
    <Stack spacing={3}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFCode name="otp" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting.value}
      >
        {t('nft_members_lang_149')}
      </LoadingButton>
    </Stack>
  );

  const renderHead = (
    <Stack spacing={1} sx={{ mb: 5 }}>
      <Typography variant="h3">
        {step === 3 ? t('nft_members_lang_146') : t('nft_members_lang_143')}
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {step === 1 && t('nft_members_lang_144')}
        {step === 2 && `Enter a new password for ${getValues().email}`}
      </Typography>

      {step === 3 && (
        <Box>
          <Typography variant="body1">{t('nft_members_lang_144')}</Typography>
          <Typography variant="caption">{t('nft_members_lang_148')}</Typography>
        </Box>
      )}
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {step === 1 && enterEmailForm}

      {step === 2 && enterPwdForm}

      {step === 3 && enterOtpForm}
    </FormProvider>
  );
}
