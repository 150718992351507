// import { paths } from 'src/routes/paths';

// export const navConfig = [
//   {
//     title: 'nft_members_lang_067',
//     icon: 'ph:sneaker-duotone',
//     path: paths.dashboard.inventory.root,
//   },
//   {
//     title: 'nft_members_lang_068',
//     icon: 'solar:cart-large-bold-duotone',
//     path: paths.dashboard.store.root,
//   },
//   {
//     title: 'nft_members_lang_069',
//     icon: 'ph:wallet-duotone',
//     path: paths.dashboard.wallet.root,
//   },
//   {
//     title: 'nft_members_lang_070',
//     icon: 'lets-icons:user-duotone',
//     path: paths.dashboard.profile.root,
//   },
// ];

import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SvgColor from 'src/components/svg-color';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import fetcher from 'src/utils/fetcher';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  membership: icon('ic_membership'),
  deposit: icon('ic_deposit'),
  withdraw: icon('ic_withdraw'),
  com: icon('ic_invoice'),
  nft: icon('ic_nft'),
  scan: icon('ic_scan'),
  setting: icon('ic_setting'),
  system: icon('ic_system'),
  binary: icon('ic_binary'),
  wallet: icon('ic_wallet'),
  sneaker: icon('ic_sneaker'),
  cart: icon('ic_cart'),
  money: icon('ic_money'),
  walletOutlined: icon('ic_wallet_outlined'),
  transaction: icon('ic_transaction'),
  support: icon('ic_support'),
  affiliate: icon('ic_affiliate'),
  document: icon('ic_document'),
  ic_walkup: icon('ic_walkup'),
  ic_staking: icon('ic_staking'),
  stakingNFT: icon('ic_staking_nft'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t, currentLang } = useLocales();
  const [supportStatus, setSupportStatus] = useState(false);

  const getSupportStatus = useCallback(() => {
    fetcher().get(
      '/support-ticket/status',
      (res) => {
        if (res.openTicket) {
          setSupportStatus(true);
        } else setSupportStatus(false);
      },
      (err) => {}
    );
  }, []);

  useEffect(() => {
    getSupportStatus();
  }, [getSupportStatus]);

  const getLinkByLang = useCallback(
    (linkEn, linkKr) => {
      switch (currentLang.value) {
        case 'en':
          return linkEn;
        case 'ko':
          return linkKr;
        default:
          return linkEn;
      }
    },
    [currentLang.value]
  );

  const data = useMemo(
    () => [
      {
        subheader: '',
        items: [
          {
            title: 'nft_members_lang_004',
            path: paths.dashboard.store.root,
            icon: ICONS.cart,
          },
          {
            title: 'nft_members_lang_067',
            path: paths.dashboard.inventory.root,
            icon: ICONS.sneaker,
          },
          {
            title: 'nft_members_lang_276',
            path: paths.dashboard.stakingNft.root,
            icon: ICONS.stakingNFT,
          },
          {
            title: 'nft_members_lang_069',
            path: paths.dashboard.wallet.root,
            icon: ICONS.wallet,
          },
          {
            title: 'nft_members_lang_211',
            path: paths.dashboard.wallet.comWallet,
            icon: ICONS.walletOutlined,
          },
          {
            title: 'nft_members_lang_237',
            path: paths.dashboard.profile.affiliate,
            icon: ICONS.affiliate,
          },
          {
            title: 'nft_members_lang_070',
            path: paths.dashboard.profile.root,
            icon: ICONS.user,
          },
          {
            title: 'nft_members_lang_294',
            path: paths.dashboard.staking.root,
            icon: ICONS.ic_staking,
          },
          {
            title: 'nft_members_lang_265',
            path: paths.dashboard.support.root,
            icon: ICONS.support,
            info: supportStatus ? (
              <Box
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  width: 10,
                  height: 10,
                  borderRadius: `999px !important`,
                }}
              />
            ) : null,
          },
          {
            title: 'Walkup',
            path: 'walkup',
            icon: ICONS.ic_walkup,
            children: [
              {
                title: 'FAQs',
                path: paths.faqs,
              },
              {
                title: 'nft_members_lang_261',
                path: getLinkByLang(
                  'https://nftmembers.gitbook.io/walk-up/walk-up-ios',
                  'https://nftmembers.gitbook.io/walk-up/walk-up-ios'
                ),
              },
              {
                title: 'nft_members_lang_262',
                path: getLinkByLang(
                  'https://nftmembers.gitbook.io/walk-up',
                  'https://nftmembers.gitbook.io/walk-up'
                ),
              },
              {
                title: 'nft_members_lang_275',
                path: getLinkByLang(
                  'https://nftmembers.gitbook.io/walk-up/lbank',
                  'https://nftmembers.gitbook.io/walk-up/lbank'
                ),
              },
              {
                title: 'nft_members_lang_277',
                path: getLinkByLang(
                  'https://nftmembers.gitbook.io/walk-up/nft',
                  'https://nftmembers.gitbook.io/walk-up/nft'
                ),
              },
              {
                title: 'nft_members_lang_263',
                path: getLinkByLang(
                  'https://whitepaper.walkup.world/english/walk-to-your-wealth',
                  'https://whitepaper-kr.walkup.world/korean/walk-to-your-wealth'
                ),
              },
            ],
          },
          {
            title: 'nft_members_lang_278',
            path: 'docs',
            icon: ICONS.document,
            children: [
              {
                title: '[아이폰 경우]_WalkUp 앱 가이드',
                path: 'https://drive.google.com/file/d/1O1Bm9-lBHZhRFn7MWH0srKIBM4lmTZY8/view?usp=sharing',
              },
              {
                title: '[안드로이드폰 경우]_WalkUp 앱 가이드',
                path: 'https://drive.google.com/file/d/1oHYR8T5e29IGljkgjV4OQwnXAa71NO8x/view?usp=sharing',
              },
              {
                title: '멤버십 웹 사이트 사용 가이드',
                path: 'https://drive.google.com/file/d/1KHhjA2C0ok1TN5-DdckTdA6Em1vjTijm/view?usp=sharing',
              },
              {
                title: 'LBank 사용 가이드',
                path: 'https://drive.google.com/file/d/1FB2LD9l031iOQbqXj-yIWhtqBftOet5V/view?usp=sharing',
              },
            ],
          },
        ],
      },
    ],
    [getLinkByLang, supportStatus]
  );

  return data;
}
